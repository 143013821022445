
.info-icon:hover {
  color: orange;
  size: 100;
}

#flot {
 min-height: 14vh;
 /*background-color: blueviolet;*/
}

#info-icon {
    animation-duration: 1s;
    animation-name: slidein;
    animation-iteration-count: 15;
    animation-direction: alternate;
    font-size: 250%;
}

#info-icon:hover {

  font-size: 260%;
}

@keyframes slidein {
  from {
    font-size: 250%;
    color: rgb(241, 234, 225);
    /*
    width : 5%;
    margin-left:100%;
    width:300%
    */
  }

  to {
    font-size: 300%;
    color: chocolate;
    /*
    width : 100%;
    margin-left:0%;
    width:100%;
    */
  }
}


html {
  min-height:100%;
}

body {
  background: linear-gradient(to bottom, #380e0a 30%, #b41212 100%) no-repeat;
  min-height: 100%;
  overflow-x: hidden;
}

.shadowStyle2{
  box-shadow: inset 0 0 0.08em rgb(199, 95, 95), 0 0 0.08em rgb(209, 173, 173)
 
}

.shadowStyle2{
  box-shadow: inset 0 0 0.2em rgb(199, 95, 95), 0 0 0.2em rgb(209, 173, 173)
 
}

::-webkit-scrollbar {
  background: #db1d30;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(0.25turn, #e05a1c, #e09e24, #eedc3a);
  /* background-image: linear-gradient(red 5%, yellow 95%); */
  border-radius: 25px;
}

@import url(https://fonts.googleapis.com/css?family=Rubik);


@supports(display: grid) {

  .grid-container {
    display: grid;
  }

  .grid-item {
    color: #fff;
  }
  
  .items {
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    transition: all 0.2s;
    white-space: nowrap;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;

  }

  .items.active {
    background: rgb(39, 5, 4);
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }

  .item {
    display: inline-block;
    background: rgb(235, 134, 63);
    margin: 2em 1em;
  }
}


.items p {
  text-align: left;
}

.textSize{
  font-size:240%;
}

@media screen and (max-width: 500px) {
  .textSize {
    font-size:130%;
    margin-top: 20%;
  }
}